import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import Swap from './Swap'

import './style.css';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
  align-items: center;
  position:relative;
  background: url(${process.env.REACT_APP_PUBLIC_URL}/images/beer-background.jpg) rgb(203,149,69,0.2);
  background-size:cover;
  background-blend-mode: multiply;
  transition: background 1s ease-in-out;
`

const BodyWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  max-width:100%;
  margin-top: 64px;
  display:flex;
  flex-wrap:wrap;
  width:100%;
  justify-content:center;
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0;
  }
`
const FlexSeparator = styled.div`
width: 60px;
height:60px;
`



export default function App() {
  return (
    <AppWrapper style={{overflow: 'hidden'}}>
      <div className="bubble x1"/>
      <div className="bubble x2"/>
      <div className="bubble x3"/>
      <div className="bubble x4"/>
      <div className="bubble x5"/>
      <div className="bubble x7"/>
      <div className="bubble x8"/>
      <div className="bubble x10"/>
      <div className="bubble x11"/>
      <div className="bubble x12"/>
      <div className="bubble x13"/>
      <div className="bubble x14"/>
      <div className="bubble x15"/>
      <div className="bubble x16"/>
      <div className="bubble x17"/>
      <div className="bubble x18"/>
      <div className="bubble x19"/>
      <div className="bubble x20"/>
      <Suspense fallback={null}>
        <HashRouter>
          <Popups />
          <BodyWrapper>
            <Web3ReactManager>
              <>
              {/* TODO REMOVE THIS DISABLE  */}
                <Swap/>
              </>
            </Web3ReactManager>
          </BodyWrapper>
        </HashRouter>
      </Suspense>
    </AppWrapper>
  )
}
