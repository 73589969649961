import React from 'react'
import styled from 'styled-components'
import { useLocation, Link, useRouteMatch } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem, NotificationDot } from '@pancakeswap/uikit'

interface SwapTabButtonsProps {
  mode?: string
  onChangeMode?: (mode:string)=>void
}

const SwapTabButtons: React.FC<SwapTabButtonsProps> = ({onChangeMode, mode}) => {
  let activeIndex: number;
  switch (mode) {
    case 'swap':
      activeIndex = 0
      break
    case 'earn':
      activeIndex = 1
      break
    default:
      activeIndex = 0
      break
  }
  return (
    <Wrapper>
      <ButtonMenu onItemClick={(i)=>{
        if (onChangeMode){ onChangeMode( i === 0 ? 'swap' : 'earn')}}} 
        activeIndex={activeIndex} scale="sm" variant="subtle">
        <ButtonMenuItem as='button'>
          Swap
        </ButtonMenuItem>
        <ButtonMenuItem as='button'>
          Earn
        </ButtonMenuItem>
      </ButtonMenu>
    </Wrapper>
  )
}

export default SwapTabButtons

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    padding-left: 12px;
    padding-right: 12px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 16px;
  }
`
