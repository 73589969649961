import JSBI from 'jsbi'
import { CurrencyAmount, Currency, BigintIsh } from '@uniswap/sdk-core'
import { MIN_ETH } from '../constants'

const MIN_NATIVE_CURRENCY_FOR_GAS: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH

/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
 export function maxAmountSpend(currencyAmount?: CurrencyAmount<Currency>): CurrencyAmount<Currency> | undefined {
  if (!currencyAmount) return undefined
  if (currencyAmount.currency.isNative) {
    if (JSBI.greaterThan(currencyAmount.quotient as unknown as JSBI, MIN_ETH)) {
      return CurrencyAmount.fromRawAmount(
        currencyAmount.currency,
        JSBI.subtract(currencyAmount.quotient as unknown as JSBI, MIN_NATIVE_CURRENCY_FOR_GAS) as unknown as BigintIsh
      )
    }
    return CurrencyAmount.fromRawAmount(currencyAmount.currency, JSBI.BigInt(0) as unknown as BigintIsh)
  }
  return currencyAmount
}

export default maxAmountSpend
