import React from 'react'
import { Text } from '@pancakeswap-libs/uikit'
import { ChainId, currencyEquals } from '@uniswap/sdk'
import { Token, Currency } from '@uniswap/sdk-core'
import { COMMON_BASES } from 'constants/index'
import styled from 'styled-components'

import useI18n from 'hooks/useI18n'
import { AutoColumn } from '../Column'
import { currencyId } from '../../utils/currencyId'
import QuestionHelper from '../QuestionHelper'
import { AutoRow } from '../Row'
import CurrencyLogo from '../CurrencyLogo'

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.colors.tertiary)};
  border-radius: 10px;
  display: flex;
  padding: 6px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ theme, disable }) => !disable && theme.colors.invertedContrast};
  }

  background-color: ${({ theme, disable }) => disable && theme.colors.tertiary};
  opacity: ${({ disable }) => disable && '0.4'};
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const TranslateString = useI18n()
  const bases = typeof chainId !== 'undefined' ? COMMON_BASES[chainId] ?? [] : []
  return (
    <AutoColumn gap="md">
      <AutoRow>
        <Text fontSize="14px">Base Tokens</Text>
        <QuestionHelper text={TranslateString(1204, 'These tokens are commonly paired with other tokens.')} />
      </AutoRow>
      <AutoRow gap="4px">
        {bases.map((currency: Currency) => {
          const isSelected = selectedCurrency?.equals(currency)
          return (
            <BaseWrapper
              onClick={() => !isSelected && onSelect(currency)}
              disable={isSelected}
              key={currencyId(currency)}
            >
              <CurrencyLogo currency={currency} style={{ marginRight: 8 }} />
              <Text fontWeight={500} fontSize='16px'>
                {currency.symbol}
              </Text>
            </BaseWrapper>
          )
        })}
      </AutoRow>
    </AutoColumn>
  )
}
