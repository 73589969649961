import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Button, Flex, Input, Text } from '@pancakeswap-libs/uikit'
import { MouseoverTooltip } from 'components/Tooltip';
import { useUserSlippageTolerance } from 'state/user/hooks'

import QuestionHelper from '../QuestionHelper'
import { INITIAL_ALLOWED_SLIPPAGE } from '../../constants';

// 100 = 1%.
const MAX_SLIPPAGE = 10000
const RISKY_SLIPPAGE_LOW = 2500
const RISKY_SLIPPAGE_HIGH = 9800

const Option = styled.div`
  padding: 0 4px;
`

const Options = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;

  ${Option}:first-child {
    padding-left: 0;
  }

  ${Option}:last-child {
    padding-right: 0;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`

const predefinedValues = [
  { label: '25%', value: 25 },
  { label: '30%', value: 30 },
  { label: '40%', value: 40 },
  { label: 'Auto', value: INITIAL_ALLOWED_SLIPPAGE/100},
]

type SlippageToleranceSettingsModalProps = {
  translateString: (translationId: number, fallback: string) => string
}

const SlippageToleranceSettings = ({ translateString }: SlippageToleranceSettingsModalProps) => {
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()
  const [value, setValue] = useState<string | number>(userSlippageTolerance / 100)
  const [error, setError] = useState<string | null>(null)
  
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = evt.target
    setValue(inputValue)
  }

  // Updates local storage if value is valid
  useEffect(() => {
    try {
      const rawValue = Number(value) * 100
      if (!Number.isNaN(rawValue) && rawValue > 0 && rawValue < MAX_SLIPPAGE) {
        setUserslippageTolerance(rawValue)
        setError(null)
      } else {
        setError(translateString(1144, 'Enter a valid slippage percentage'))
      }
    } catch {
      setError(translateString(1144, 'Enter a valid slippage percentage'))
    }
  }, [value, setError, setUserslippageTolerance, translateString])

  // Notify user if slippage is risky
  useEffect(() => {
    if (userSlippageTolerance < RISKY_SLIPPAGE_LOW) {
      setError(translateString(1146, 'Your transaction will fail. Enter a value above 25%'))
    } else if (userSlippageTolerance > RISKY_SLIPPAGE_HIGH) {
      setError(translateString(1148, 'Your transaction may be frontrun'))
    }
  }, [userSlippageTolerance, setError, translateString])

  return (
    <Box mb="16px">
      <Flex alignItems="center" mb="8px">
        <Text bold>{translateString(88, 'Slippage tolerance')}</Text>
        <QuestionHelper
          text={translateString(
            186,
            'Your transaction will revert if the price changes unfavorably by more than this percentage. \n Minimum is 15% due to this token\'s deflationary burn and distribute functions.'
          )}
        />
      </Flex>
      <Options>
        <Flex mb={['8px', '8px', 0]} mr={[0, 0, '8px']}>
          {predefinedValues.map(({ label, value: predefinedValue }) => {
            const handleClick = () => setValue(predefinedValue)

            return (
              <Option key={predefinedValue}>
                <Button
                  className={value === predefinedValue ? 'primaryBtn' : 'tertiaryBtn'}
                  onClick={handleClick}
                >
                  {label}
                </Button>
              </Option>
            )
          })}
        </Flex>
        <Flex alignItems="center">
          <Option>
            <Input
              type="hidden"
              scale="lg"
              step={0.1}
              min={0.1}
              value={value}
              isWarning={error !== null}
            />
            <Input
              type="text"
              scale="lg"
              step={0.1}
              min={0.1}
              placeholder="25%"
              value={Number(value) === INITIAL_ALLOWED_SLIPPAGE/100 ? 'Auto' : value}
              onChange={handleChange}
              isWarning={error !== null}
            />
          </Option>
          <Option>
            <Text fontSize="18px">%</Text>
          </Option>
        </Flex>
      </Options>
      {error && (
        <Text mt="8px" color="failure">
          {error}
        </Text>
      )}
      <Text mt="4px" fontSize="10px" color='grey'>
        <br/>
        * Consider  {" "}
        <MouseoverTooltip 
          fontSize='10px'
          text='15% buy tax and 0% to 20% sell tax depending on how long you hold for. Collected tax is distributed to existing holders as ETH dividends.'>
          <u>tax</u>
        </MouseoverTooltip> 
        {" "} when selecting slippage. You will need at minimum 15% slippage.
        <br/>
        ** Sells tax is variable. 20% for selling within a week, 10% for selling within a month. 
      </Text>
    </Box>
  )
}

export default SlippageToleranceSettings
