import React from 'react';
import styled from 'styled-components'

const Card = styled.div<any>`
  width: 100%;
  border-radius: 16px;
  padding: 1.25rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`
export default Card

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.invertedContrast};
  background-color: ${({ theme }) => theme.colors.invertedContrast};
`

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.tertiary};
`

const ConnectButtonContainer = styled.span`
  width: 80%;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 0px 20px 20px white;
`

const OverlayBg = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1.5px);
  top: 0;
  left: 0;
  z-index: 100;
`
export const Overlay = ({ children }) => (
  <OverlayBg>
    <ConnectButtonContainer>{children}</ConnectButtonContainer>
  </OverlayBg>
)
