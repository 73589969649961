import JSBI from 'jsbi'
import { Trade } from '@uniswap/v2-sdk'
import { Currency, CurrencyAmount, Percent, TradeType, BigintIsh } from '@uniswap/sdk-core'

import {
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_LOW,
  ALLOWED_PRICE_IMPACT_MEDIUM,
} from '../constants'

import { Field } from '../state/swap/actions'
import { basisPointsToPercent } from './index'

const BASE_FEE = new Percent(JSBI.BigInt(20) as unknown as BigintIsh, JSBI.BigInt(10000) as unknown as BigintIsh)
const ONE_HUNDRED_PERCENT = new Percent(JSBI.BigInt(10000) as unknown as BigintIsh, JSBI.BigInt(10000) as unknown as BigintIsh)
const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// computes price breakdown for the trade
export function computeTradePriceBreakdown(
  trade?: Trade<Currency, Currency, TradeType>
): { priceImpactWithoutFee?: Percent; realizedLPFee?: CurrencyAmount<Currency> } {
  // for each hop in our trade, take away the x*y=k price impact from 0.2% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .02) * (1-.02))
  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(
        trade.route.pairs.reduce<Percent>(
          (currentFee: Percent): Percent => currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
          ONE_HUNDRED_PERCENT
        )
      )

  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction = trade && realizedLPFee ? trade.priceImpact.subtract(realizedLPFee) : undefined

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(priceImpactWithoutFeeFraction?.numerator, priceImpactWithoutFeeFraction?.denominator)
    : undefined

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    CurrencyAmount.fromRawAmount(trade.inputAmount.currency, trade.inputAmount.multiply(realizedLPFee).quotient)

  return { priceImpactWithoutFee: priceImpactWithoutFeePercent, realizedLPFee: realizedLPFeeAmount }
}

// computes the minimum amount out and maximum amount in for a trade given a user specified allowed slippage in bips
export function computeSlippageAdjustedAmounts(
  allowedSlippage: number,
  trade?: Trade<Currency, Currency, TradeType>
): { [field in Field]?: CurrencyAmount<Currency> } {
  const pct = basisPointsToPercent(allowedSlippage)
  const zeroPct = basisPointsToPercent(0)
  const outPct = basisPointsToPercent(10000-allowedSlippage)
  const actualIn = trade?.maximumAmountIn(pct)
  const actualOut = trade?.minimumAmountOut(zeroPct).multiply(outPct) as CurrencyAmount<Currency>
  return {
    [Field.INPUT]: actualIn,
    [Field.OUTPUT]: actualOut,
  }
}

export function warningSeverity(priceImpact: Percent | undefined): 0 | 1 | 2 | 3 | 4 {
  if (!priceImpact?.lessThan(BLOCKED_PRICE_IMPACT_NON_EXPERT)) return 4
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) return 3
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return 2
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_LOW)) return 1
  return 0
}

export function formatExecutionPrice(trade?: Trade<Currency, Currency, TradeType> , inverted?: boolean): string {
  if (!trade) {
    return ''
  }
  return inverted
    ? `${trade.executionPrice.invert().toSignificant(6)} ${trade.inputAmount.currency.symbol} / ${
        trade.outputAmount.currency.symbol
      }`
    : `${trade.executionPrice.toSignificant(6)} ${trade.outputAmount.currency.symbol} / ${
        trade.inputAmount.currency.symbol
      }`
}
