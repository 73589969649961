import JSBI from 'jsbi'
import { Currency, CurrencyAmount, Token, TradeType, Percent, BigintIsh } from '@uniswap/sdk-core'
import { Trade } from '@uniswap/v2-sdk'
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'
import { CardBody, Button, Text, Link, Flex, Heading } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import { AutoColumn } from 'components/Column'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import { GreyCard, Overlay } from 'components/Card'
import { BottomGrouping, Wrapper } from 'components/swap/styleds'
import Container from 'components/Container'

import { useActiveWeb3React } from 'hooks'
import { useCurrency } from 'hooks/Tokens'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import { Field } from 'state/swap/actions'
import { useDerivedSwapInfo, useSwapActionHandlers, useSwapState } from 'state/swap/hooks'
import { useUserDeadline, useUserSlippageTolerance } from 'state/user/hooks'
import { StyledPageHeader, Details } from 'components/PageHeader'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { formatBalanceAmount } from 'utils/formatBalance'
import { useContract } from '../../hooks/useContract'
import DIV_TOKEN_ABI from '../../constants/abis/divtoken.json'

import AppBody from '../AppBody'

import { useCurrencyBalance } from '../../state/wallet/hooks'

interface HarvestProps {
  ETHPrice?: string
  disabled?: boolean
}
const Harvest = ({ ETHPrice = '0.00', disabled }: HarvestProps) => {
  const { account, chainId, library } = useActiveWeb3React()

  // get custom setting values for user
  const [deadline] = useUserDeadline()
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { typedValue, recipient } = useSwapState()
  const { v2Trade, currencies, inputError: swapInputError } = useDerivedSwapInfo()
  const { wrapType } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = showWrap ? undefined : v2Trade

  const { onUserInput } = useSwapActionHandlers()
  const isValid = !swapInputError

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, showConfirm: false }))

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [onUserInput, txHash, setSwapState])

  const handleAcceptChanges = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, tradeToConfirm: trade }))
  }, [trade])

  /* TODO CHANGE THIS ADDRESS  */
  const currencyAddress = '0x2E92864240819E2286d440B0c477077Dd660b340'
  const farmAddress = '0x574Fc478BC45cE144105Fa44D98B4B2e4BD442CB'
  const currency = useCurrency(currencyAddress)
  const divTokenBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)

  const [estEthEarnings, setEstEthEarnings] = useState<null | number>(null)
  const [estUsdEarnings, setEstUsdEarnings] = useState<null | number>(null)
  const [totEthDis, setTotEthDis] = useState<null | number>(null)
  const [totUsdDis, setTotUsdDis] = useState<null | number>(null)
  const [loadingClaim, setLoadingClaim] = useState(false)
  const [message, setMessage] = useState('')
  const farmContract = useContract(farmAddress, DIV_TOKEN_ABI, true)

  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(
          'https://api.coingecko.com/api/v3/coins/beer-inu?tickers=false&market_data=true&community_data=false&developer_data=false&sparkline=false'
        )

        const res = await response.json()
        setTotEthDis(res.market_data.total_volume.eth * 0.17)
        setTotUsdDis(res.market_data.total_volume.usd * 0.17)
        const shareUserHas = Number(divTokenBalance?.toSignificant(6)) / Number(res.market_data.total_supply)
        const ethEarnings = shareUserHas * Number(res.market_data.total_volume.eth) * 0.17
        const usdEarnings = shareUserHas * Number(res.market_data.total_volume.usd) * 0.17
        setEstUsdEarnings(usdEarnings)
        setEstEthEarnings(ethEarnings)
      } catch (e) {
        console.log('pe 01', e)
      }
    }
    getData()
  }, [account, farmContract, chainId, divTokenBalance])

  const handleClaim = useCallback(() => {
    if (farmContract) {
      setLoadingClaim(true)
      setMessage('')
      farmContract
        .withdraw(currencyAddress)
        .then(() => {
          setLoadingClaim(false)
          setMessage('Success')
        })
        .catch((e) => {
          setLoadingClaim(false)
          setMessage(`${e.message}`)
        })
    }
  }, [farmContract])

  const earningsLoaded =
    !Number.isNaN(Number(divTokenBalance?.toSignificant(2))) &&
    !Number.isNaN(Number(estEthEarnings)) &&
    !Number.isNaN(Number(estUsdEarnings))
  return (
    <>
      {(!account || disabled) && (
        <Overlay>
          {!account && !disabled ? (
            <ConnectWalletButton label="Connect Wallet to Earn" width="100%" />
          ) : (
            <>
              <Text> PRIVATE PRESALE LIVE NOW </Text>
              <Text fontSize="12px"> Rewards to be enabled on public listing</Text>
            </>
          )}
        </Overlay>
      )}
      <Wrapper id="swap-page" style={{ paddingTop: 0, display: 'flex', flexDirection: 'column' }}>
        <StyledPageHeader style={{ padding: 10 }}>
          <Flex alignItems="center">
            <Details>
              <Text color="textSubtle" fontSize="16px" textAlign="center">
                Total Global Earnings (24h)
              </Text>
              <br />
              <Heading mb="8px" textAlign="center" fontSize="40px">
                {totEthDis && totUsdDis ? (
                  <>
                    {` ${totEthDis.toFixed(6)} ETH`} <br />{' '}
                    {`~ ${totUsdDis.toLocaleString(undefined, { style: 'currency', currency: 'USD' })}`}
                  </>
                ) : (
                  'Loading...'
                )}
                <br />
              </Heading>
            </Details>
          </Flex>
        </StyledPageHeader>
        <br />
        <CardBody style={{ position: 'relative' }}>
          <AutoColumn gap="md">
            Your HODLings: <br /> <br />
            {`${divTokenBalance?.toSignificant(6) || 'Loading...'} `} BEER
            <br />
            <br />
            <br />
            Your Estimated Earnings (24H): <br />
            <br />
            {earningsLoaded
              ? ` ${estEthEarnings?.toFixed(6)} ETH ~ ${estUsdEarnings?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'USD',
                })}`
              : ' Loading...'}
            <br />
            <br />
            <br />
          </AutoColumn>
          <BottomGrouping>
            <Button
              className="bgTheme"
              onClick={() => {
                handleClaim()
              }}
              id="claim-button"
              disabled={loadingClaim}
              width="100%"
            >
              {loadingClaim ? 'Awaiting Wallet Confirmation...' : 'Claim Earnings'}
            </Button>
            <Text fontSize="10px" textAlign="center">
              {' '}
              {message}{' '}
            </Text>
          </BottomGrouping>
        </CardBody>
      </Wrapper>
    </>
  )
}

export default Harvest
