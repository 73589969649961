import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0);
  max-width:100%;
  margin: 20px 30px;

  ${({ theme }) => theme.mediaQueries.xs} {
  }

  ${({ theme }) => theme.mediaQueries.lg} {
  }
`

export default Container
